.dselect-wrapper .form-select {
  padding-left: 0.75rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.25rem;
  text-align: left;
}
.dselect-wrapper .form-select.show {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.dselect-wrapper .form-select.dselect-clearable {
  padding-right: 3.25rem;
}
.dselect-wrapper .form-select.form-select-sm {
  padding-left: 0.5rem;
}
.dselect-wrapper .form-select.form-select-sm.dselect-clearable {
  padding-right: 3.125rem;
}
.dselect-wrapper .form-select.form-select-lg {
  padding-left: 1rem;
}
.dselect-wrapper .form-select.form-select-lg.dselect-clearable {
  padding-right: 3.375rem;
}
.dselect-wrapper .dropdown-menu {
  padding: 0.375rem;
  width: 100%;
}
.dselect-wrapper .dropdown-menu > .flex-column {
  gap: 0.375rem;
}
.dselect-wrapper .form-select-sm + .dropdown-menu {
  padding: 0.25rem;
  font-size: 0.875rem;
}
.dselect-wrapper .form-select-lg + .dropdown-menu {
  padding: 0.5rem;
  font-size: 1.25rem;
}
.dselect-wrapper .dropdown-item,
.dselect-wrapper .dropdown-header {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.dselect-wrapper .form-select-sm + .dropdown-menu .dropdown-item,
.dselect-wrapper .form-select-sm + .dropdown-menu .dropdown-header {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.dselect-wrapper .form-select-lg + .dropdown-menu .dropdown-item,
.dselect-wrapper .form-select-lg + .dropdown-menu .dropdown-header {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.dselect-wrapper .form-control {
  border-radius: 0;
  box-shadow: none !important;
  border-color: #ced4da !important;
  padding: 0.25rem calc(0.375rem - 1px);
  font-size: inherit;
}
.dselect-wrapper .form-select-sm + .dropdown-menu .form-control {
  padding: calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.dselect-wrapper .form-select-lg + .dropdown-menu .form-control {
  padding: 0.25rem calc(0.5rem - 1px);
}
.dselect-wrapper .dselect-no-results {
  padding: 0.25rem 0.375rem;
}
.dselect-wrapper .form-select-sm + .dropdown-menu .dselect-no-results {
  padding: calc(0.25rem - 1px) 0.25rem;
}
.dselect-wrapper .form-select-lg + .dropdown-menu .dselect-no-results {
  padding: 0.25rem 0.5rem;
}
.dselect-wrapper .dselect-tag {
  background-color: #6c757d;
  color: #f8f9fa;
  padding-left: calc(0.5rem + 14px);
  padding-right: 0.5rem;
  border-radius: 0.25rem;
  height: calc(1.5 * 1rem);
  line-height: calc((1.5 * 1rem) - 1px);
  position: relative;
}
.dselect-wrapper .form-select-sm .dselect-tag {
  height: auto;
  line-height: inherit;
}
.dselect-wrapper .form-select-lg .dselect-tag {
  height: calc(1.5 * 1.25rem);
  line-height: calc(1.5 * 1.25rem);
}
.dselect-wrapper .dselect-tag-remove {
  position: absolute;
  left: 0.25rem;
  top: 50%;
  margin-top: -7px;
  color: rgba(248, 249, 250, 0.35);
  transition: color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .dselect-wrapper .dselect-tag-remove {
    transition: none;
  }
}
.dselect-wrapper .dselect-tag-remove:hover {
  color: #f8f9fa;
}
.dselect-wrapper .dselect-placeholder {
  color: #6c757d;
}
.dselect-wrapper .dropdown-header ~ .dropdown-item {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.dselect-wrapper .form-select-sm + .dropdown-menu .dropdown-header {
  font-size: 0.85em;
}
.dselect-wrapper .form-select-sm + .dropdown-menu .dropdown-header ~ .dropdown-item {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.dselect-wrapper .form-select-lg + .dropdown-menu .dropdown-header {
  font-size: 0.85em;
}
.dselect-wrapper .form-select-lg + .dropdown-menu .dropdown-header ~ .dropdown-item {
  padding-left: 1rem;
  padding-right: 1rem;
}
.dselect-wrapper .dselect-clear {
  position: absolute;
  padding: 0;
  border: 0;
  box-shadow: none;
  top: 0;
  bottom: 0;
  right: 2.25rem;
  display: flex;
  align-items: center;
  color: #6c757d;
  cursor: pointer;
}
.dselect-wrapper .dselect-clear:hover {
  color: inherit;
}
.dselect-wrapper .dselect-clear svg {
  width: 0.625rem;
  height: 0.625rem;
}
.dselect-wrapper [data-dselect-text=""] ~ .dselect-clear {
  display: none;
}
.was-validated .form-select:invalid + .dselect-wrapper .form-select, .form-select.is-invalid + .dselect-wrapper .form-select {
  border-color: #dc3545;
}
.was-validated .form-select:invalid + .dselect-wrapper .form-select.show, .was-validated .form-select:invalid + .dselect-wrapper .form-select:focus, .form-select.is-invalid + .dselect-wrapper .form-select.show, .form-select.is-invalid + .dselect-wrapper .form-select:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-select:valid + .dselect-wrapper .form-select, .form-select.is-valid + .dselect-wrapper .form-select {
  border-color: #198754;
}
.was-validated .form-select:valid + .dselect-wrapper .form-select.show, .was-validated .form-select:valid + .dselect-wrapper .form-select:focus, .form-select.is-valid + .dselect-wrapper .form-select.show, .form-select.is-valid + .dselect-wrapper .form-select:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

/*# sourceMappingURL=dselect.css.map */
